<template>
  <div class="slider-swiper" ref="slider">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>

    <div class="swiper-button-prev teaser__arrow" ref="prev">
      <IconLeft />
    </div>
    <div class="swiper-button-next teaser__arrow" ref="next">
      <IconRight />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Swiper, { Autoplay, Navigation } from 'swiper';
import { onMounted, ref, nextTick } from 'vue';
import IconLeft from '../../../icons/icon-chevron-left.svg?component';
import IconRight from '../../../icons/icon-chevron-right.svg?component';

const props = defineProps({
  config: {
    type: Object,
    default: () => {},
  },
});

const slider = ref();
const prev = ref();
const next = ref();

onMounted(() => {
  nextTick(
      () =>
          new Swiper(slider.value, {
            modules: [Navigation, Autoplay],
            // Optional parameters
            direction: 'horizontal',
            // loop: true,
            speed: 1400,
            slidesPerView: 1,
            spaceBetween: 30,
            breakpoints: {
              1201: {
                spaceBetween: 40,
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
            },
            grabCursor: true,
            navigation: {
              nextEl: next.value,
              prevEl: prev.value,
            },
            ...props.config,
          })
  );
});
</script>

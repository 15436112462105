<template>
  <Paginator :config="config" class="v-paginator">
    <template v-slot="{ paginator, toPostComponent, hideFilter, noEntriesFoundText, loadMore }">
      <LightboxFilter v-if="!hideFilter && filterViewMode === 'lightbox'" :paginator="paginator"/>

      <div class="v-paginator-posts">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1">
            <div v-if="showAsSimpleSlider" class="v-paginator-posts__slider">
                <Slider class="news-slider">
                  <component class="swiper-slide news-slide" v-for="post in paginator.posts.value" :key="post.id"
                             :is="toPostComponent(post.id)"></component>
                </Slider>
            </div>
            <div v-else class="v-paginator-posts__wrapper">
              <slot name="posts" v-bind="{ paginator, toPostComponent }">
                <component v-for="post in paginator.posts.value" :key="post.id"
                           :is="toPostComponent(post.id)"></component>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <div v-if="noEntriesFoundText && !paginator.posts.value.length && !paginator.loading.value">{{ noEntriesFoundText }}</div>
      <div v-if="loadMore && paginator.hasNextPage.value" class="font--middle v-paginator-load-more">
        <div class="load-more" @click="paginator.loadMore()">
          <InlineSpinner v-if="paginator.loading.value"/>
          <span>{{ $t('paginator.more') }}</span>
          <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M.793.793a1 1 0 0 1 1.414 0L6.5 5.086 10.793.793a1 1 0 1 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414Z"
                  fill="currentColor"/>
          </svg>
        </div>
      </div>
    </template>
  </Paginator>
</template>

<script setup lang="ts">
import {InlineSpinner, Paginator} from 'wly-theme-extensions';
import LightboxFilter from '@/js/vue/components/Paginator/LightboxFilter.vue';
import Slider from '@/js/vue/components/Slider.vue';
import {computed} from 'vue';

const props = defineProps({
  config: {
    type: Object,
    required: true,
  },
  filterViewMode: {
    type: String,
    default: () => 'lightbox',
  },
  viewMode: {
    type: String,
    default: () => 'list',
  }
});


const showAsSimpleSlider = computed(() => props.viewMode === 'slider');

</script>

const tabElements = document.querySelectorAll('[data-role="gfc-tabs"]');

function getTabContentElements(childElements) {
    let tabContentElements = [];
    childElements.forEach((child) => {
        let tabContentElement = document.querySelector('#' + child.dataset.gfcTabsTargetId);
        if (!tabContentElement) {
            return;
        }
        tabContentElements.push(tabContentElement);
    });
    return tabContentElements;
}

function hideTabContentElements(tabContentElements) {
    tabContentElements.forEach((tabContentElement) => {
        tabContentElement.style.display = 'none';
    });
}

function addControlledClass(tabContentElements) {
    tabContentElements.forEach((tabContentElement) => {
        if (!tabContentElement.classList.contains('tab--controlled')) {
            tabContentElement.classList.add('tab--controlled');
        }
    });
}

function initTabs(tabElement) {
    const childElements = Array.from(tabElement.children);
    let tabContentElements = getTabContentElements(childElements);

    // Füge initial die Klasse "tab--controlled" hinzu
    addControlledClass(tabContentElements);

    childElements.forEach((child) => {
        let tabContentElement = document.querySelector('#' + child.dataset.gfcTabsTargetId);
        if (!tabContentElement) {
            return;
        }

        child.addEventListener('click', (event) => {
            event.preventDefault();
            const clickedElement = event.currentTarget;
            hideTabContentElements(tabContentElements);
            let clickedTabContentElement = document.querySelector('#' + clickedElement.dataset.gfcTabsTargetId);
            if (!clickedTabContentElement) {
                return;
            }
            clickedTabContentElement.style.removeProperty('display');
        });
    });

    tabContentElements.forEach((tabContentElement, index) => {
        if (tabContentElement && index !== 0) {
            tabContentElement.style.display = 'none';
        }
    });
}

for (let tabElement of tabElements) {
    initTabs(tabElement);
}